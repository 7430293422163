import Img29 from '../assets/images/logo.png'
import { Link } from 'react-router-dom'
import React, { useState } from "react";
import "./Navbar.css";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaYoutubeSquare,
} from "react-icons/fa";
import { GiHamburgerMenu } from "react-icons/gi";


const Header = () => {

    const [showMediaIcons, setShowMediaIcons] = useState(false);


  return (

    <div> 
        
        <nav className="main-nav">
        {/* 1st logo part  */}
        <div className="logo">
          <img src={Img29} className='logoimg'/>
        </div>

        {/* 2nd menu part  */}
        <div
          className={
            showMediaIcons ? "menu-link mobile-menu-link" : "menu-link"
          }>
         <ul>
                                <li><Link to="/" data-scroll-nav='0'>home</Link></li>
                                <li className='nav-item'>
                        <Link to='/Buycredits'>Buy Credits</Link>
                    </li>
                                <li><a href='#about' data-scroll-nav='1'>about</a></li>
                                <li><a href='#service' data-scroll-nav='2'>services</a></li>
                                <li><a href='#game' data-scroll-nav='3'>games</a></li>
                                <li><a href='#plans' data-scroll-nav='4'>plans</a></li>
                                <li class="blog_dropdown"><a href='#blog' data-scroll-nav='5'>blog <i class="fa fa-caret-down"></i></a>
                                </li>
                                <li><a href='#contact'>contact</a></li>
                            </ul>
        </div>

        {/* 3rd social media links */}
        <div className="social-media">
     

          {/* hamburget menu start  */}
          <div className="hamburger-menu">
            <a href="#" onClick={() => setShowMediaIcons(!showMediaIcons)}>
              <GiHamburgerMenu />
            </a>
          </div>
        </div>
      </nav>


       </div>
  )
}

export default Header