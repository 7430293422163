import React,{useState} from 'react'
import Img15 from '../assets/images/line.png'
import Img16 from '../assets/images/games/game-img4.png'
import Img17 from '../assets/images/games/pandamaster.png'
import Img18 from '../assets/images/games/riversweep.png'
import Img19 from '../assets/images/games/vegas.png'
import Img30 from '../assets/images/games/2240d94d287144e79adfb6d947006306239647.png'
import Img31 from '../assets/images/games/juwa.png'
import Img32 from '../assets/images/games/mikyway.png'
import Img33 from '../assets/images/games/orionstar.png'
import Img35 from '../assets/images/games/gamevault.png'
import Img36 from '../assets/images/games/firekaren.png'
import Img37 from '../assets/images/games/thefatboyz.png'
import Img38 from '../assets/images/games/9bf10f418fa82319ca9957a6107fcb0a659708.png'
import Img51 from '../assets/images/games/king of pop.png'
import Img52 from '../assets/images/games/noble.png'
import Img53 from '../assets/images/games/luckystar.png'
import Img54 from '../assets/images/games/mafia.png'
import Img55 from '../assets/images/games/orian power.png'
import Img56 from '../assets/images/games/winstar.png'
import Img57 from '../assets/images/games/highroller.png'
import Img58 from '../assets/images/games/moolah.png'
import Img59 from '../assets/images/games/gameroom.png'
import Img60 from '../assets/images/games/gemslots.png'
import Img61 from '../assets/images/games/mrallinone.png'
import Img62 from '../assets/images/games/cashmachine.png'
import './Dropdown.css';
import { IoMdArrowDropdown } from "react-icons/io";


const Buycredits = () => {
    const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [selectedOption, setSelectedOption] = useState('');

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleButtonClick = () => {
    let link;
    switch (selectedOption) {
      case 'volvo':
        link = 'https://www.facebook.com/messages/t/296837906846381';
        break;
      case 'saab':
        link = 'https://www.facebook.com/messages/t/296837906846381';
        break;
      case 'fiat':
        link = 'https://www.facebook.com/messages/t/296837906846381';
        break;
      case 'audi50k':
        link = 'https://www.facebook.com/messages/t/296837906846381';
        break;
      case 'audi100k':
        link = 'https://www.facebook.com/messages/t/296837906846381';
        break;
      default:
        link = '';
    }

    if (link) {
      window.location.href = link;
    } else {
      alert('Please select an option');
    }
  };

  return (
    <div>
       <div id="game" style={{marginTop:"150px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="px_heading wow frombottom">
                            <h5>games</h5>
                            <h1>Buy Games <span>Credits</span></h1>
                            <img src={Img15} alt="Alexa Games"/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img19} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Vegas X</h2><br/>
                                              <h6>Gaming Credits</h6>  

                                              <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/> 
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img text-center">
                                <img src={Img16}  alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>E Game</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img17} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Panda Master</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img18} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Riversweep</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img30} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>V Blink</h2>
                                                <h6>Gaming Credits</h6>  <br/>
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img31} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Juwa</h2>
                                                <h6>Gaming Credits</h6>  <br/>
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img32} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Milky Way</h2>
                                                <h6>Gaming Credits</h6>  <br/>
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img33} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Orion Stars</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                   
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img35} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Game Vault</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img36} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Fire Kirin</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img37} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>The Fat Boyz</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img38} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Ultra Panda</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img51} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>King Of Pop</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img52} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Noble</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
             
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img53} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Lucky Star</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
           
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img54} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Mafia</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
              
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img55} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Orion Power</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
           
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img56} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Win Star</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img57} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>High Rollers</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img58} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Moolah</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img59} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Game Room</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img60} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>GEM SLOTS</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img61} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Mr All In One</h2><br/>
                                                <h6>Gaming Credits</h6>  
                                                <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>
                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img62} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Cash Machine</h2><br/>
                                                <h6>Gaming Credits</h6>  
               
      <div class="custom-select-wrapper">
                <div class="custom-select"> 
            <select id="cars" name="cars" className="px_btn selectop mt-3" onChange={handleSelectChange}>
        <option value="" disabled selected style={{ display: 'none' }}>Select an action</option>
        <option value="volvo">1000 Credits - $160</option>
        <option value="saab">5k Credits - $750</option>
        <option value="fiat">10k Credits - $1400</option>
        <option value="audi50k">50k Credits - $6750</option>
        <option value="audi100k">100k Credits - $13000</option>
      </select> 
        </div>
    </div>

                                    </div>
                                   
                            </div>
                            <div class='text-center'>
                            <button onClick={handleButtonClick} class="creditbuybutton">Buy Now</button>
                            </div>
                            <br/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Buycredits