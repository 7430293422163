import React from 'react'
import {FaFacebookF} from 'react-icons/fa'
import {BsTwitter} from 'react-icons/bs'
import {ImGooglePlus} from 'react-icons/im'
import {GrInstagram} from 'react-icons/gr'
const Footer = () => {
  return (
    <div><div class="px_bottom_footer">
    <div class="container">
        <div class="footer-middle">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="widget widget_about wow fadeInLeft" data-wow-delay="300ms"
                    data-wow-duration="1500ms">
                        <h4 class="widget-title">About us</h4>
                        <div class="details">
                            <p class="mb-3">We are giving 24/7 Customer Support. We have a team inside which will entertain you and give you fast response with in a seconds.</p>
                           
                            <p><svg class="svg-inline--fa fa-envelope fa-w-16" aria-hidden="true" focusable="false" data-prefix="fa" data-icon="envelope" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>Contact@alexagamer.com</p>
                            <ul class="social-media">
                                <li>
                                    <a class="facebook" target='_blank' href="https://www.facebook.com/profile.php?id=100086737662541">
                                        <FaFacebookF/>
                                    </a>
                                </li>
                                <li>
                                    <a class="twitter" href="https://twitter.com/AlexaGames777">
                                        <BsTwitter/>
                                    </a>
                                </li>
                                <li>
                                    <a class="googleplus" href="mailto:alexagames318@gmail.com">
                                        <ImGooglePlus style={{fontSize:22}}/>
                                    </a>
                                </li>
                                <li>
                                    <a class="twitter" href="https://instagram.com/alexagames.official?utm_source=qr&igshid=MThlNWY1MzQwNA==" target='_blank'>
                                        <GrInstagram/>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
           
              
                <div class="col-lg-4 col-md-6">
                <div class="accordion accordion-flush" id="accordionFlushExample">
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingOne">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
      01. How do I create social casino Account ?
      </button>
    </h2>
    <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Directly message us on messenger we will create your account and give you the account’s username and password

</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingTwo">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo" aria-expanded="false" aria-controls="flush-collapseTwo">
      02. How do I recharge my  Account ?
            </button>
    </h2>
    <div id="flush-collapseTwo" class="accordion-collapse collapse" aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">We give you our cashtag once you sent the money let us know and we will recharge your account with bonus.</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingThree">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
      03. where should I get the link of game?      </button>
    </h2>
    <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Message us we give you the link of game where you will download the game directly.

</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingfour">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefour" aria-expanded="false" aria-controls="flush-collapseOne">
      04. How do I  cashout once I win?
            </button>
    </h2>
    <div id="flush-collapsefour" class="accordion-collapse collapse" aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">Once you win the game let us know we will check your account if you win it, we ask you from your cashtag then we will direclty transfer you the amount of your winning.

</div>
    </div>
  </div>
</div>
                </div>
                 
                <div class="col-lg-4 col-md-6">
                <div class="accordion accordion-flush" id="accordionFlushExample">
 
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingfive">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsefive" aria-expanded="false" aria-controls="flush-collapseTwo">
      05. what is our min and maximum cashout  limit ?      </button>
    </h2>
    <div id="flush-collapsefive" class="accordion-collapse collapse" aria-labelledby="flush-headingTfive" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">For Cashout details you can contact our offical page <a href='https://www.facebook.com/profile.php?id=100086737662541' target='_blank' style={{color:'skyblue'}}>Alexa Games</a></div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingsix">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapsesix" aria-expanded="false" aria-controls="flush-collapseThree">
      06. What is our min and maximum deposit limit?      </button>
    </h2>
    <div id="flush-collapsesix" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">For min and maximum deposit details you can contact our offical page <a href='https://www.facebook.com/profile.php?id=100086737662541' target='_blank' style={{color:'skyblue'}}>Alexa Games</a>
</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingseven">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseseven" aria-expanded="false" aria-controls="flush-collapseThree">
      07. How you will get the free play on everyweek? </button>
    </h2>
    <div id="flush-collapseseven" class="accordion-collapse collapse" aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">First you need to be our regular customer then you will be eligible for our freeplay bonus requirements.
</div>
    </div>
  </div>
  <div class="accordion-item">
    <h2 class="accordion-header" id="flush-headingeight">
      <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapeight" aria-expanded="false" aria-controls="flush-collapseThree">
      08. How Should I get Referral Bonus </button>
    </h2>
    <div id="flush-collapeight" class="accordion-collapse collapse" aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
      <div class="accordion-body">You need to give a referral to us and once your referral deposit we will directly message you and give you 50% of Referral's Deposit.
</div>
    </div>
  </div>
</div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="px_btm_footer">
    <div class="container">
        <div class="row">
            <div class="col-lg-12">
                <p class="copyright">Copyright © 2018, All Rights Reserved by Alexa Games.</p>
            </div>
        </div>
    </div>
</div></div>
  )
}

export default Footer