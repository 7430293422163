import React, { useState, useEffect }  from "react";
import Home from "./components/pages/Home";
import "./components/assets/css/style.css"
import Footer from "./components/pages/footer";
import Contact from "./components/pages/Contact";
import Header from "./components/pages/Header";
import { BrowserRouter,Route,Routes } from 'react-router-dom'
import Modal from 'react-modal';
import Img1 from './components/assets/images/Ageimage.png'
import Buycredits from "./components/pages/Buycredits";
function App() {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [age, setAge] = useState();

  useEffect(() => {
    setModalIsOpen(true);
  }, []);

  function closeModal() {
    if (age >= 18) {
      setModalIsOpen(false);
    } else {
      alert("You must be at least 18 years old to use this site.");
    }
  }

  function handleVerification(isOver18) {
    if (isOver18) {
      setModalIsOpen(false);
    } else {
      alert("You must be at least 18 years old to use this site.");
    }
  }
  return (
    <div className="App">

<Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Age Verification Modal"
  className="modalContent"
  style={{
    overlay: {
      zIndex: 10000, // or any number higher than the highest z-index on your page
      background:'#00000082'
    }
  }}
>
  <div style={{textAlign:'center'}}>
<img src={Img1} width={150} alt="Alexa Games" class="img-fluid" />
</div>
        <p style={{color:"white"}}>You must be at least 18 years old to use this site.</p>
        <div>
        <button style={{borderRadius:50,height:50,color:'White',background:'red',fontSize:25,padding:"0px 15px",margin:5}} onClick={() => handleVerification(false)}>No, I am under 18</button>
        <button style={{borderRadius:50,height:50,color:'White',background:'green',fontSize:25,padding:"0px 15px",margin:5}} onClick={() => handleVerification(true)}>Yes, I am over 18</button>
        </div>

</Modal>

      <BrowserRouter >
      
<Header/>
<Routes>
  
<Route path='/' element={<Home/>} />
<Route path='/Contact' element={<Contact/>} />
<Route path='/Buycredits' element={<Buycredits/>} />


<Route path='*' element={<Home/>}/>
</Routes>

<Footer/>
</BrowserRouter>

    
      

    
     
    </div>
  );
}

export default App;
