import React from 'react'
import Img1 from '../assets/images/line.png'
import {BsEnvelope} from 'react-icons/bs'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {BiPhoneCall} from 'react-icons/bi'
import {BsClock} from 'react-icons/bs'
import { Link } from 'react-router-dom'
const Contact = () => {
  return (
    <div>

<div class="breadcrumb-area contactbanner mt-5" >
        <div class="container">
            <div class="breadcrumb-inner">
                <div class="section-title mb-0">
                    <h2 class="page-title">Contact Us</h2>
                    <ul class="page-list">
                        <li><Link to="/">Home</Link></li>
                        <li> / Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div class="px_contact_wrapper " data-scroll-index='7'>
    <div id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="px_heading bottom_marger50 frombottom">
                        <h1>contact <span>us</span></h1>
                        <img src={Img1} alt="clubhouse-about-head-border"/>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon">
                            <BsEnvelope style={{fontSize:30}}/>
                        </div>
                        <h2>Email</h2>
                        <p>Info@example.com</p>
                        <p>support@example.com</p>   
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon icon2">
                            <FaMapMarkerAlt style={{fontSize:30}}/>
                        </div>
                        <h2>Address</h2>
                        <p>71 Pilgrim Avenue </p>
                        <p>Chevy Chase, MD 20815</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon icon3">
                           <BiPhoneCall style={{fontSize:30}}/>
                        </div>
                        <h2>Phone</h2>
                        <p>+1-202-555-0127</p>
                        <p>+1-202-555-0192</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon  icon4">
                            <BsClock style={{fontSize:30}}/>
                        </div>
                        <h2>Opening Time</h2>
                        <p>Mon-Fri : 20:00 - 2:00</p>
                        <p>Sat-Sun : 18:00 -2:00</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px_contact_map zoomIn" data-wow-delay="300ms"
    data-wow-duration="1500ms">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="px_map wow frombottom">
                        <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6998912.340024612!2d-105.37662824272246!3d31.069750624943417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20USA!5e0!3m2!1sen!2s!4v1686129416723!5m2!1sen!2s" width="100%" height="440px" ></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

    </div>
  )
}

export default Contact