import React,{ useCallback,useState, useEffect,useRef } from 'react'
import Img1 from '../assets/images/fact.png'
import Img2 from '../assets/images/fact2.png'
import Img3 from '../assets/images/fact3.png'
import Img4 from '../assets/images/line.png'
import Img5 from '../assets/images/about.png'
import Img6 from '../assets/images/line.png'
import Img7 from '../assets/images/services/ser-1.png'
import Img8 from '../assets/images/services/ser-2.png'
import Img9 from '../assets/images/services/ser-3.png'
import Img14 from '../assets/images/services/ser-8.png'
import Img15 from '../assets/images/line.png'
import Img16 from '../assets/images/games/game-img4.png'
import Img17 from '../assets/images/games/pandamaster.png'
import Img18 from '../assets/images/games/riversweep.png'
import Img19 from '../assets/images/games/vegas.png'
import Img30 from '../assets/images/games/2240d94d287144e79adfb6d947006306239647.png'
import Img31 from '../assets/images/games/juwa.png'
import Img32 from '../assets/images/games/mikyway.png'
import Img33 from '../assets/images/games/orionstar.png'
import Img35 from '../assets/images/games/gamevault.png'
import Img36 from '../assets/images/games/firekaren.png'
import Img37 from '../assets/images/games/thefatboyz.png'
import Img38 from '../assets/images/games/9bf10f418fa82319ca9957a6107fcb0a659708.png'
import Img20 from '../assets/images/line.png'
import Img21 from '../assets/images/line.png'
import Img28 from '../assets/images/res_img.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Particles from "react-tsparticles";
import { loadFull } from "tsparticles";
import {BsEnvelope} from 'react-icons/bs'
import {FaMapMarkerAlt} from 'react-icons/fa'
import {BiPhoneCall} from 'react-icons/bi'
import {BsClock} from 'react-icons/bs'
import Img29 from '../assets/images/Facebook_Messenger_logo_2020.png'
import Img39 from '../assets/images/bronze.png'
import Img40 from '../assets/images/sliver.png'
import Img41 from '../assets/images/gold.png'
import Img42 from '../assets/images/sarah.jpg'
import Img43 from '../assets/images/evie.jpg'
import Img44 from '../assets/images/tricia.jpg'
import Img45 from '../assets/images/unnamed.jpg'
import Img46 from '../assets/images/unnamed2.jpg'
import Img47 from '../assets/images/Casino-Insert-Image.jpg'
import Img48 from '../assets/images/Gala_Games.jpg'
import Img49 from '../assets/images/Scrn_BlackjackArena_01.png'
import Img50 from '../assets/images/jackport.jpg'
import { FaFacebookMessenger } from "react-icons/fa";
import Img51 from '../assets/images/games/king of pop.png'
import Img52 from '../assets/images/games/noble.png'
import Img53 from '../assets/images/games/luckystar.png'
import Img54 from '../assets/images/games/mafia.png'
import Img55 from '../assets/images/games/orian power.png'
import Img56 from '../assets/images/games/winstar.png'
import Img57 from '../assets/images/games/highroller.png'
import Img58 from '../assets/images/games/moolah.png'
import Img59 from '../assets/images/games/gameroom.png'
import Img60 from '../assets/images/games/gemslots.png'
import Img61 from '../assets/images/games/mrallinone.png'
import Img62 from '../assets/images/games/cashmachine.png'
import Img63 from '../assets/images/payment/cashapp.png'
import Img64 from '../assets/images/payment/chime.png'
import Img65 from '../assets/images/payment/crypto.png'
import Img66 from '../assets/images/payment/paypal.png'
import Img67 from '../assets/images/payment/vemno.png'
import Img68 from '../assets/images/payment/zelle.png'





import emailjs from '@emailjs/browser';

import Swal from 'sweetalert2'
const Home = () => {

    const form=useRef()
	const sendEmail = (e) => {
		e.preventDefault();
	
		emailjs.sendForm('service_z4j82uj', 'template_p4g4bal', form.current, '_TNsk7eVHJMiThjAe')
		  .then((result) => {
 
			Swal.fire({
				icon: 'success',
				title: 'Form Submited'				
			  })		
  }, (error) => {
			  console.log(error.text);
		  });
		  e.target.reset()
	  };
    
    const particlesInit = useCallback(async engine => {
        console.log(engine);
        await loadFull(engine);
    }, []);

    const particlesLoaded = useCallback(async container => {
        await console.log(container);
    }, []);

    const responsive = {
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 4,
          slidesToSlide: 3 // optional, default to 1.
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 2,
          slidesToSlide: 2 // optional, default to 1.
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1,
          slidesToSlide: 1 // optional, default to 1.
        }
      };
 

  return (
    
    <div>
     
        <div class="px_banner_wrapper" data-scroll-index='0'>
            
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="px_banner_slider">
                      
                        <div class="item text-center">
                            <h1>Alexa Games</h1>
                            <h3 className='mt-3'>Play Unlimited Slots & Fishing Games !!!
</h3>
<a target="_blank" href="https://www.facebook.com/messages/t/100880882821967" class="px_btn mt-4 mx-2">Play Now</a>
<a target="_blank" href="https://www.facebook.com/messages/t/100880882821967" class="px_btn mt-4 mx-2">Join Us<FaFacebookMessenger size={20}/></a>
<a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-4 mx-2">Pay Crypto</a>

                        </div>
                    </div>
                </div>
            </div>
        </div>
       
        <div id="js-particles"></div>
        
    </div>
    <div style={{position:"absolute",width:"100%",zIndex:5,marginTop:"-220px"}}>
    <div style={{background:"none"}}>
        <Carousel
                    arrows={false}
  swipeable={false}
  draggable={true}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlaySpeed={2000}
  autoPlay
  slidesToSlide={1}
  keyBoardControl={true}
  transitionDuration={1500}
  containerClass="carousel-fluid"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  className='px-1'
>
    
  <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img45})` }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Fish Games</b></h3>
                        </div>

                        <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img46})`,backgroundPosition:"center" }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Slot Game</b></h3>
                        </div>
                        <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img47})`,backgroundPosition:"center" }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Roulette Games</b></h3>
                        </div>
                        <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img48})`,backgroundPosition:"center" }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Pokar Games</b></h3>
                        </div>
                        <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img50})`,backgroundPosition:"center" }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Jackport Games</b></h3>
                        </div>
                        <div class="element element-1 col-md-12 wow flipInY text-center" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="gameshowcase gameshowcaseimg " style={{   backgroundImage:`url(${Img49})`,backgroundPosition:"center" }}>
                             
                            </div>
                            <h3 className='mt-3'><b>Blackjacks Games</b> </h3>
                        </div>
                        

 
                  
                      
                    
                      
</Carousel>;
        </div>
    </div>

    
 


    <div class="px_game_wrapper " data-scroll-index='3'>
    <div id="game" style={{marginTop:"150px"}}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="px_heading wow frombottom">
                        <h5>PAYMENT METHODS</h5>
                        <h1>NOW ACCECPTING PAYMENTS ON</h1>
                        <img src={Img15} alt="Alexa Games"/>
                    </div>
        <div class="container" style={{marginTop:40}}>
            <div class="row justify-content-center">
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInLeft" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="details"><br/>
                            <img src={Img63} alt="" width="70%"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card default-card-active wow fadeInUp" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        
                        <div class="details"><br/>
                         <img src={Img64} alt="" width="70%"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="details"><br/>
                        <img src={Img65} alt="" width="70%"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="details"><br/>
                        <img src={Img66} alt="" width="70%"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="details"><br/>
                        <img src={Img67} alt="" width="70%"/>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="details"><br/>
                        <img src={Img68} alt="" width="70%" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    

                </div>

            </div>
        </div>
    </div>
   


        <div id="game" style={{marginTop:"150px"}}>
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="px_heading wow frombottom">
                            <h5>games</h5>
                            <h1>play <span>games</span></h1>
                            <img src={Img15} alt="Alexa Games"/>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img19} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Vegas X</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>
                                                <a target="_blank" href="https://www.vegas-x.org/" class="px_btn mt-3 mx-1">Download</a>

                                    </div>
                                    
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img text-center">
                                <img src={Img16}  alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>E Game</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://egame99.club/ " class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img17} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Panda Master</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://pandamaster.vip:8888/index.html" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img18} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Riversweep</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.river777.net/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img30} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>V Blink</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.vblink777.club/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img31} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Juwa</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://dl.juwa777.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img32} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Milky Way</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://milkywayapp.xyz/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img33} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Orion Stars</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://start.orionstars.vip:8580/index.html" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                   
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img35} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Game Vault</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://download.gamevault999.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img36} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>Fire Kirin</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://start.firekirin.xyz:8580/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img37} width={50} alt="Alexa Games" class="img-fluid" />
                                <div class="text-center mt-4 ">
                                                <h2>The Fat Boyz</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://thefatboyz.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img38} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Ultra Panda</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.ultrapanda.mobi/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img51} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>King Of Pop</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.slots88888.com" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img52} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Noble</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://www.noble777.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
             
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img53} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Lucky Star</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://www.luckystars.games/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
           
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img54} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Mafia</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.mafia77777.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
              
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img55} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Orion Power</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://download.orionpower.games:8008/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
           
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img56} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Win Star</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.winstar99999.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img57} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>High Rollers</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://highrollerdownload.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img58} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Moolah</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://moolah.vip:8888/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img59} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Game Room</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="http://www.gameroom777.com/" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                            
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img60} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>GEM SLOTS</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://gem77777.com" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img61} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Mr All In One</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://www.mrallinone777.com/m" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-6 col-12">
                        <div class="px_game_box wow zoomIn " data-wow-delay="300ms"  data-wow-duration="1500ms"
                        >
                            <div class="px_game_img">
                                <img src={Img62} width={10} alt="Alexa Games" class="img-fluid" />
                               
                                   <div class="text-center mt-4 ">
                                                <h2>Cash Machine</h2>
                                                <a target="_blank" href="https://payments.alexagames.us/success.php" class="px_btn mt-3 mx-1">Play Now</a>

                                                <a target="_blank" href="https://www.cashmachine777.com/m" class="px_btn mt-3 mx-1 ">Download</a>

                                    </div>
                                   
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Particles
               id="tsparticles"
               init={particlesInit}
               loaded={particlesLoaded}
               options={{
                   background: {
                       color: {
                           value: "none",
                       },
                   },
                   fpsLimit: 120,
                   interactivity: {
                       events: {
                           onClick: {
                               enable: true,
                               mode: "bubble",
                           },
                           onHover: {
                               enable: true,
                               mode: "none",
                           },
                           resize: true,
                       },
                       modes: {
                           push: {
                               quantity: 4,
                           },
                           repulse: {
                               distance: 200,
                               duration: 0.4,
                           },
                       },
                   },
                   particles: {
                       color: {
                           value: "#59D1EF",
                       },
                       links: {
                           color: "#ffffff",
                           distance: 150,
                           enable: false,
                           opacity: 0.3,
                           width: 5,
                       },
                       collisions: {
                           enable: true,
                       },
                       move: {
                           direction: "top",
                           enable: true,
                           outModes: {
                               default: "out",
                           },
                           random: true,
                           speed: 2,
                           straight: false,
                       },
                       number: {
                           density: {
                               enable: true,
                               area: 1000,
                           },
                           value: 100,
                       },
                       opacity: {
                           value: 0.5,
                       },
                       shape: {
                           type: "circle",
                       },
                       size: {
                           value: { min: 2, max: 5 },
                       },
                   },
                   detectRetina: true,
               }}
           />   
    </div>
   
    <div class="px-fact-area bg-cover bg-overlay-2 factareabg" >
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInLeft" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="thumb">
                            <img src={Img1} alt="Alexa Games"/>
                        </div>
                        <div class="details">
                            <h3><span class="counter">70,257</span>+ </h3>
                            <p>Total Registered User</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card default-card-active wow fadeInUp" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="thumb">
                            <img src={Img2} alt="Alexa Games"/>
                        </div>
                        <div class="details">
                            <h3><span class="counter">15464K</span> </h3>
                            <p>Total Withdraw</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-4 col-sm-6">
                    <div class="single-fact-inner default-card wow fadeInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <div class="default-card-inner"></div>
                        <div class="thumb">
                            <img src={Img3} alt="Alexa Games"/>
                        </div>
                        <div class="details">
                            <h3><span class="counter">57,581</span>+ </h3>
                            <p>Active Investor Daily</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px_about_wrapper" data-scroll-index='1'>
        <div id="about">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="px_heading  wow frombottom">
                            <h5>Welcome</h5>
                            <h1>About <span>Us</span></h1>
                            <img src={Img4} alt="Alexa Games"/>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="px_about_data wow slideInLeft" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <h2>About Alexa Games</h2>
                            <p>At Alexa Games, we are passionate about delivering an exceptional gaming experience in the world of online-social-casino games. Our dedicated team of professionals is committed to setting the standard for quality, innovation, and fairness in the industry. We take pride in offering a wide variety of online-social-casino games, from classic table games to the latest slots and live dealer experiences. Whether you're a seasoned player or new to the world of online-social-casino gaming, Alexa Games is your trusted destination for a diverse range of thrilling games. Our mission is to create an engaging and secure environment where every player can enjoy top-notch online-social-casino games. We invite you to explore our platform and become a part of the Alexa Games community today.</p>
                            <a href="https://www.facebook.com/messages/t/100880882821967" class="px_btn">learn more</a>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="px_about_img wow slideInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <img src={Img5} alt="clubhouse-about" class="img-fluid" />
                           
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="px_freetip_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="px_freetip_header wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms">
                        <h3>GET FREE TIPS</h3>
                        <h2>Have any question please feel free contact us</h2>
                        <a href="https://www.facebook.com/messages/t/100880882821967" class="px_btn">learn more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="px_service_wrapper" data-scroll-index='2'>
        <div id="service">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-sm-12 mb-5">
                        <div class="px_heading wow frombottom">
                            <h5>Services</h5>
                            <h1>our <span>services</span></h1>
                            <img src={Img6} alt="Alexa Games"/>
                        </div>
                    </div>
              
                    <div class="slick-slider row">
                    <Carousel
                    arrows={false}
  swipeable={false}
  draggable={true}
  showDots={false}
  responsive={responsive}
  ssr={true} // means to render carousel on server-side.
  infinite={true}
  autoPlaySpeed={4000}
  autoPlay
  slidesToSlide={1}
  keyBoardControl={true}
  transitionDuration={1500}
  containerClass="carousel-fluid"
  removeArrowOnDeviceType={["tablet", "mobile"]}
  className='px-1'
>
    
  <div class="element element-1 col-md-12 wow flipInY" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="px_service_box servicebg ">
                                <div class="px_service_box_img ">
                                <img src={Img7} alt="Alexa Games"/>
                            </div>
                                <h3>Welcome Bonus</h3>
                                <p>This offered to New Players making their first deposit.This is done by giving players bonus credits that they can use together with their money to increase their winnings.</p>
                            </div>
                        </div>

                        <div class="element element-8 wow flipInY col-md-12" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="px_service_box servicebg">
                                <div class="px_service_box_img">
                                <img src={Img14} alt="Alexa Games"/>
                                </div>
                                <h3>Referral Bonus</h3>
                                <p>Friends can be a blessing and a bonus. Let your friends or family know how good your top-notch Alexa Bell is, and give them a chance to get in on the fun, too.</p>
                            </div>
                        </div>

                        <div class="element element-3 wow flipInY col-md-12" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="px_service_box servicebg">
                                <div class="px_service_box_img">
                                <img src={Img9} alt="Alexa Games"/>
                                </div>
                                <h3>24/7 Cashout</h3>
                                <p>We know that High speed payments, especially withdrawals, are important to players. We're offering instant payouts for their customers through most popular and secure software.</p>
                            </div>
                        </div>

  <div class="element element-2 col-md-12 wow flipInY" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="px_service_box servicebg">
                                <div class="px_service_box_img">
                                <img src={Img8} alt="Alexa Games"/>
                                </div>
                                <h3>Game Distributer</h3>
                                <p>online-social-casino games provider. Alexa Games is a dynamic game provider of slots and fishing gaming. We create innovative and engaging products featured worldwide</p>
                            </div>
                        </div>

 
                  
                      
                    
                      
</Carousel>;
                        
                    
                        
                        
                       
                        
                       
                       
                      </div>
                </div>
            </div>
        </div>
    </div>


  

   


    <div class="px_plans_wrapper" data-scroll-index='4'>
        <div id="plans">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="px_heading wow frombottom">
                            <h5>our Cashout Plan</h5>
                            <h1>Cashout <span>plans</span></h1>
                            <img src={Img20} alt="Alexa Games"/>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <img src={Img39} width='95%' alt="Alexa Games" />
                    </div>
                    <div class="col-lg-4 col-md-6 col-12">
                        <img src={Img40} width='95%' alt="Alexa Games" />
                        
                    </div>
                    <div class="col-lg-4 col-md-6 col-12 offset-md-3">
                        <img src={Img41} width='95%' alt="Alexa Games" />
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="px_blog_wrapper" data-scroll-index='5'>
        <div id="blog">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="px_heading bottom_marger50 wow frombottom">
                            <h5>Our Testimonial </h5>
                            <h1>Read the latest Testimonial </h1>
                            <img src={Img21} alt="Alexa Games"/>
                        </div>
                    </div>
                    <div class="container">
        <div class="row">
            <div class="col-lg-4 ">
                <div class="card m-auto">
                    <div class="face front-face">
                        <img src={Img42}
                            alt="Alexa Games" class="profile"/>
                        <div class="pt-3 text-uppercase name">
                            Sarah Loosmore
                        </div>
                    </div>
                    <div class="face back-face">
                        <span class="fas fa-quote-left"></span>
                        <div class="testimonial">
                        Alexa Bell is my go to gamer! She is always available and never make me wait to load my deposit. The best part?? IMMEDIATE payouts! No waiting at all! Thank you Alexa!
                        </div>
                        <span class="fas fa-quote-right"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card m-auto">
                    <div class="face front-face">
                        <img src={Img43}
                            alt="Alexa Games" class="profile"/>
                        <div class="pt-3 text-uppercase name">
                        Evie Wahlmeier
                        </div>
                    </div>
                    <div class="face back-face">
                        <span class="fas fa-quote-left"></span>
                        <div class="testimonial">
                        I give you guys a 5 out of star! Fastest to cash out and the most fun to play with. Plus you have all of the games I love and some I haven't even tried yet. you guys are one of the only online-social-casino games i trust!
                        </div>
                        <span class="fas fa-quote-right"></span>
                    </div>
                </div>
            </div>
            <div class="col-lg-4">
                <div class="card m-auto">
                    <div class="face front-face">
                        <img src={Img44}
                            alt="Alexa Games" class="profile"/>
                        <div class="pt-3 text-uppercase name">
                        Tricia Ballard
                        </div>
                    </div>
                    <div class="face back-face">
                        <span class="fas fa-quote-left"></span>
                        <div class="testimonial">
                        Ok so for anyone who likes gaming I would highly recommend ALEXA!!!! It definitely gives you the thrills and excitement as if you are actually at the online-social-casino, and the people are like part of your family, and always there to help and answer any questions you might have. To walk away without anything in your pockets is rare.
                        </div>
                        <span class="fas fa-quote-right"></span>
                    </div>
                </div>
            </div>
        </div>
    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="px_feature_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="px_freetip_header wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                    >
                        <h3>FEATURES OF SPORTS BETTING</h3>
                        <h2>Our Slots & Fishing Games</h2>
                        <a href="https://www.facebook.com/messages/t/100880882821967" class="px_btn">learn more</a>
                    </div>
                </div>
            </div>
        </div>
    </div>


    
    <div class="px_contact_wrapper " data-scroll-index='7'>
    <div id="contact">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="px_heading bottom_marger50 frombottom">
                        <h1 className='mb-5'>contact <span>us</span></h1>
                        <img src={Img1} alt="Alexa Games"/>
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon">
                            <BsEnvelope style={{fontSize:30}}/>
                        </div>
                        <h2>Email</h2>
                        <p>alexagames318@gmail.com</p>
                          
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon icon2">
                            <FaMapMarkerAlt style={{fontSize:30}}/>
                        </div>
                        <h2>Address</h2>
                        <p>Texas, United States </p>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon icon3">
                           <BiPhoneCall style={{fontSize:30}}/>
                        </div>
                        <h2>Whatsapp</h2>
                        <p>+16822814061</p>
                        
                    </div>
                </div>
                <div class="col-lg-3 col-md-6 col-12">
                    <div class="px_con_box wow flipInY" data-wow-delay="300ms"
                    data-wow-duration="1500ms"
                    >
                        <div class="px_con_icon  icon4">
                            <BsClock style={{fontSize:30}}/>
                        </div>
                        <h2>Availability</h2>
                        <p>24 Hours</p>
                        
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px_reserv_wrapper" data-scroll-index='6'>
        <div id="reserv">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="px_res_img wow slideleft">
                            <img src={Img28} alt="Alexa Games" class="img-fluid" />
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="px_res_form_wrap wow slideInUp" data-wow-delay="300ms"  data-wow-duration="1500ms">
                            <div class="px_res_form">
                                <div class="px_heading bottom_marger50">
                                    <h1>Contact <span>Alexa Games</span></h1>
                                </div>
                            </div>
							<form id="contact_form" ref={form} onSubmit={sendEmail}>
                                <div class="row">						
                                    <div class="col-lg-6 col-md-6 wow slideInRight" data-wow-delay="300ms"  data-wow-duration="1500ms">
                                     
                                      
                                        <div class="px_res_input">
                                            <input class="form-control" type="text" required name="name_id" placeholder="Your Name" />
                                        </div>
                                        <div class="px_res_input">
                                            <input class="form-control require" type="text" required name="email_id" placeholder="Email" data-valid="email" data-error="Email should be valid."/>
                                        </div>
                                        <div class="px_res_input">
                                            <input class="form-control require" type="text" required name="number_id"  placeholder="Phone" data-valid="number" data-error="Number should be valid."/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 col-md-6">
                                     
                                     
                                        <div class="px_res_input">
                                            <textarea class="form-control" rows="5" name='message' required id="message" placeholder="Message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <div class="response"></div>
                                        <button class="btn px_btn submitForm" type="submit"> Contact Now </button>

                                    </div>
                                </div>
							</form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px_feature_section">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="px_freetip_header wow zoomIn" data-wow-delay="300ms"  data-wow-duration="1500ms"
                    >
                        <h2>Disclaimer 18+</h2>
                        <p style={{color:"white"}}>THIS IS A FREE-TO-PLAY SOCIAL GAMES SITE WHERE YOU USE PLAY CREDITS ONLY. NO MONEY CAN BE WON.
THIS WEBSITE DOES NOT OFFER “REAL MONEY” GAMING OPPORTUNITIES OR CHANCE TO WIN PRIZES OR GIFTS.
THIS SITE IS INTENDED FOR 18+ ADULTS ONLY. PRACTICING AT SOCIAL SITE DOES NOT GUARANTEE
SUCCESSES AT “REAL MONEY” GAMING IN FUTURE.</p>
                     
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="px_contact_map zoomIn" data-wow-delay="300ms"
    data-wow-duration="1500ms">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="px_map wow frombottom">
                        <div id="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6998912.340024612!2d-105.37662824272246!3d31.069750624943417!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x864070360b823249%3A0x16eb1c8f1808de3c!2sTexas%2C%20USA!5e0!3m2!1sen!2s!4v1686129416723!5m2!1sen!2s" width="100%" height="440px" ></iframe>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div >
    <a href="https://www.facebook.com/messages/t/100880882821967" target='_blank' className='messsengerwidget'><img src={Img29} width={140}/>
</a>

</div>
    </div>
  )
}

export default Home